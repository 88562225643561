.whatido {
  display: flex;
  margin: 100px;
  text-align: center;
  align-items: center;
}

.whatido__header {
  text-align: center;
  margin: 20px;
}

.whatido__body {
  /* d-flex justify-content-center flex-wrap mb-5 */
  justify-content: center;
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  padding: 100px;
  text-align: center;
  margin-top: 5px;
}

.card-header {
  background: transparent;
  border: none;
}

.svg {
  font-size: 100px;
}
