.header {
  background-color: transparent;
}
.nav {
  background-color: transparent;
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}
