html {
  height: 100%;
}
.home {
  display: flex;
  color: whitesmoke;
  margin: 90px;
  /* background: url("../../images/background.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

/* .bg {
  background: url("images/background.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.card {
  /* background-color: transparent; */
  border: none;
}

.home__aboutme {
  text-align: center;
  margin: 100px;
}

.home__img {
  text-align: right;
}

.home__links {
  justify-content: center;
  flex-direction: row;
}

.nav-link {
  color: white;
}
