.experience {
  display: flex;
  text-align: center;
  background: linear-gradient(75deg, #11cdef, #1ca794) !important;
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
  /* opacity: 0.8; */
}
.card-group {
  margin: 30px;
}
.card {
  flex: 1;
  background: white;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  float: none;
  border: 2px;
}
.card-header {
  padding: 1.25rem 1.5rem;
  margin: 0px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

/* .card-body svg {
  flex: auto;
  font-size: medium;
  padding: 3.5rem;
} */

.card-img-top {
  background: white;
  text-align: center;
  height: 200px;
  width: auto;
  margin-bottom: 10px;
}

.card-title {
  margin: 20px;
}
